import React, { useState, useEffect, useRef } from 'react'
import { Card, Container, Button } from 'react-bootstrap';
import { PointSpreadLoading } from 'react-loadingg';
import config from '../config';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faCheckSquare, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Logo from '../logo-color.svg';
import LogoMovil from '../logo-movil.png';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';


const Response = (props) => {
    const [reg, setReg] = useState(null);
    const [errorMsg, setError] = useState(null);
    const { url_api, headers_api } = config;
    const componentRef = useRef();

    useEffect(_ => {
        const t = props.match.params.token;
        axios.get(url_api + '/reg/' + t, headers_api).then(response => {
            setReg(response.data);
        }).catch(rs_error => {
            Swal.fire({
                icon: 'error',
                title: 'Datos no econtrados',
                text: 'No se ha encontrado ningun resultado para el enlace solicitado'
            }).then(err => {
                setError(true)  
            });
        });
    }, []);

    function setLogo() {
        if (isMobile) {
            return <img height='30' src={LogoMovil} alt='Logo Tecnocampus' className='pull-right' />
        } else {
            return <img height='30' src={Logo} alt='Logo Tecnocampus' className='pull-right' />
        }
    }

    if (!reg) {
        return (
            <Container>
                <h2 style={{ marginTop: 100 }} className="title">Formulario de nuevo acceso</h2>
                <hr />
                <Card className="main-card">

                    <Card.Body>

                        <Card.Title>
                            {errorMsg ? (
                                <Redirect to='/404' />
                            ) : (
                                    <PointSpreadLoading />
                                )}
                        </Card.Title>
                    </Card.Body>
                </Card>
            </Container>

        )

    } else {
        return (
            <Container>
                <h2 style={{ marginTop: 100 }} className="title">Formulario de nuevo acceso</h2>
                <hr />
                <Card id="ResponseCard" className="main-card">

                    <Card.Body ref={componentRef}>
                        <div className="PrintContent">
                            <Card.Title>
                                <FontAwesomeIcon style={{ fontSize: '2rem' }} icon={faClipboardCheck} color='green' />  Registro #{reg.id}
                                {setLogo()}
                            </Card.Title><hr />

                            <p>
                                Se han registrado correctamente todos tus datos.
                        </p>
                            <p>
                                El identificador correspondiente a tu registro es el  <strong style={{ fontSize: '2rem' }}>#{reg.id}</strong>
                            </p>
                            <p>
                                Se te ha mandado el justificante de registro a tu dirección de correo <a href={"mailto:" + reg.email}>{reg.email}</a>
                            </p>

                            <p>Gracias por tu colaboración.</p>
                            <hr />
                            <div className="footerResponse">
                                Fundació Tecnocampus <small className="pull-right">{reg.created_at}</small>
                            </div>
                        </div>


                    </Card.Body>

                    <Card.Footer>
                        <div className="pull-right">
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button variant="info">
                                            <FontAwesomeIcon icon={faPrint} /> Imprimir
                                    </Button>
                                    )

                                }}

                                content={() => {
                                    return componentRef.current;
                                }}

                            // copyStyles={true}
                            // bodyClass='PrintContent'
                            />

                        </div>
                    </Card.Footer>


                </Card>
            </Container>
        );
    }
}
export default Response;

