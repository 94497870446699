import React from 'react'
import {Row, Col } from 'react-bootstrap';

var Footer = () => {
    return (
        <div className="footer">
            <Row>
                <Col md={4} xs={12}>
                    <span>
                        &copy; Fundació Tecnocampus
            </span>
                </Col>
                <Col md={8} xs={12}>
                    <div className="pull-right">
                        <a href="https://www.tecnocampus.cat/es/politica-privacidad" target="_blank">Política de privacidad</a>
                        <a href="https://www.tecnocampus.cat/es/aviso-legal" target="_blank">Aviso Legal</a>
                        <a href="https://www.tecnocampus.cat/es/politica-de-cookies" target="_blank">Coockies</a>
                    </div>
                </Col>
            </Row>

        </div>
    );
}

export default Footer;