import React, { useState,useEffect } from 'react'
import { Card, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import makeAnimated from 'react-select/animated'
import RadioGroup from '../../form_elements/RadioGroup';
import NavTab from '../NavTab';
import Scroll from 'react-scroll'


const TabImpresiones = (props) => {

    const valRadioOptions = {
        1: 'Muy mala',
        2: 'Mala',
        3: 'Normal',
        4: 'Buena',
        5: 'Muy buena',
        '-1': 'No Sabe / No Contesta',
    };

    const scroll = Scroll.animateScroll

    useEffect(_=>{
        scroll.scrollToTop({duration:500});
    },[]);


    /**
   * Emite la función para meter los datos en el componente padre
   * @param {*} e 
   */
    function setField(e) {
        var key = e.target.name;
        var value = e.target.value;
        props.setData(key, value);
    }

    /**
     * Emite la función para meter los datos en el componente padre de los radios
     */
    function handleRadio(e) {
        const { name, value } = e.target;
        props.setData(name, value);
    }
   
    return (

        <Card className="main-card">
            <Card.Header>
            <NavTab 
                setStep={props.setStep} 
                current={props.data.step}
                data={props.data.data}
                />
            </Card.Header>
            <Card.Body>

                <Card.Title>7. Primeras impresiones</Card.Title><hr />

                {/* PRIMERA FILA INTERES */}

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className='required' >Valora la calidad de la información acadèmica y administrativa disponible antes de la matrícula: </Form.Label>
                            <RadioGroup
                                inline={true}
                                data={props.data.data}
                                name='look_services'
                                options={valRadioOptions}
                                handleRadio={handleRadio}
                            />

                        </Form.Group>

                    </Col>
                </Row>

                <hr />
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className='required' >Valora la calidad de la atención recibida antes de la matrícula: </Form.Label>
                            <RadioGroup
                                inline={true}
                                data={props.data.data}
                                name='look_pre_matri'
                                options={valRadioOptions}
                                handleRadio={handleRadio}

                            />

                        </Form.Group>

                    </Col>
                </Row>

                {/* PREGUNTA ELIMINADA POR NO TENER SENTIDO EN ESTE CUESTIONARIO */}
                
                <hr />
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className='required' >Valora la calidad de la atención recibida durante la formalización de la matrícula:  </Form.Label>
                            <RadioGroup
                                inline={true}
                                data={props.data.data}
                                name='look_post_matri'
                                options={valRadioOptions}
                                handleRadio={handleRadio}

                            />
                        </Form.Group>

                    </Col>
                </Row>
                <hr />
                <Row>
                    {/* COMENTARIOS */}
                    <Col>
                        <Form.Group>
                            <Form.Label >Comentarios</Form.Label>
                            <textarea name="comments" defaultValue={props.data.data.comments} onChange={setField} className="form-control" cols="30" rows="10">
                               
                            </textarea>
                        </Form.Group>
                    </Col>
                </Row>



            </Card.Body>
            <Card.Footer>
                <Button variant="warning" onClick={props.prevStep}>Anterior</Button>
                <Button className="pull-right" onClick={props.nextStep}>Siguiente</Button>
            </Card.Footer>

        </Card>
    );
}
export default TabImpresiones;

