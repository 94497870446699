import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import DatosPersonalesTab from './tabs/TabDatosPersonales';
import PhotoTab from './tabs/TabPhoto';
import DatosMatriculaTab from './tabs/TabMatricula';
import DatosProfesionalesTab from './tabs/TabProfesionales';
import DatosExpectativasTab from './tabs/TabExpectativas';
import DatosImpresionesTab from './tabs/TabImpresiones';
import PolicyTab from './tabs/TabPolicy';
import DatosInteresTab from './tabs/TabIntereses';
import { TransitionGroup, CSSTransition } from "react-transition-group"
import config from '../../config';
import axios from 'axios';
import ShowData from '../ShowData';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';
import Scroll from 'react-scroll'
import PointSpreadLoading from 'react-loadingg/lib/PointSpreadLoading';

export class FormPropis extends Component {

    state = {
        token: null,
        step: 1,
        combos: {
            genders: [],
            countries: [],
            studies: [],
            residences: [],
            orientations: [],
            knowledge: [],
        },
        data: {
            // step 1
            dni: process.env.REACT_APP_DNI,
            nombre: process.env.REACT_APP_NOMBRE,
            primer_apellido: process.env.REACT_APP_PRIMER_APELLIDO,
            segundo_apellido: process.env.REACT_APP_SEGUNDO_APELLIDO,
            email: process.env.REACT_APP_EMAIL,
            foto: process.env.REACT_APP_FOTO,
            telefono:  process.env.REACT_APP_TELEFONO,
            genero: parseInt(process.env.REACT_APP_GENERO),
            pais: parseInt(process.env.REACT_APP_PAIS), 
            ciudad: process.env.REACT_APP_CIUDAD,

            // step 2
            estudio: parseInt(process.env.REACT_APP_ESTUDIO),
            ciudad_curso: process.env.REACT_APP_CIUDAD_CURSO,
            tipo_residencia: parseInt(process.env.REACT_APP_TIPO_RESIDENCIA), 

            //step 3
            job_10h: parseInt(process.env.REACT_APP_JOB_10H),
            job_study: parseInt(process.env.REACT_APP_JOB_STUDY),
            job_fix: parseInt(process.env.REACT_APP_JOB_FIX),
            job_weekend: parseInt(process.env.REACT_APP_JOB_WEEKEND),
            job_search: parseInt(process.env.REACT_APP_JOB_SEARCH),
            practises: parseInt(process.env.REACT_APP_PRACTISES),
            job_city: process.env.REACT_APP_JOB_CITY,
            company_name: process.env.REACT_APP_COMPANY_NAME,

            //step 4
            motivo_estudios:  process.env.REACT_APP_MOTIVO_ESTUDIOS, 
            orientations:  [],    
            val_geo: parseInt(process.env.REACT_APP_VAL_GEO),
            val_horari: parseInt(process.env.REACT_APP_VAL_HORARI),
            val_price: parseInt(process.env.REACT_APP_VAL_PRICE),
            val_pay: parseInt(process.env.REACT_APP_VAL_PAY),
            val_fame: parseInt(process.env.REACT_APP_VAL_FAME),
            val_instal: parseInt(process.env.REACT_APP_VAL_INSTAL),
            val_teacher: parseInt(process.env.REACT_APP_VAL_TEACHER),
            val_upf: parseInt(process.env.REACT_APP_VAL_UPF),
            val_expects: parseInt(process.env.REACT_APP_VAL_EXPECTS),
            val_employment: parseInt(process.env.REACT_APP_VAL_EMPLOYMENT),
            knowledge:   [],  


            //step 5
            expect_knowlege: parseInt(process.env.REACT_APP_EXPECT_KNOWLEGE),
            expect_tuto: parseInt(process.env.REACT_APP_EXPECT_TUTO),
            expect_practices: parseInt(process.env.REACT_APP_EXPECT_PRACTICES),
            expect_contacts: parseInt(process.env.REACT_APP_EXPECT_CONTACTS),
            expect_employment: parseInt(process.env.REACT_APP_EXPECT_EMPLOYMENT),

            //step 6
            look_services: parseInt(process.env.REACT_APP_LOOK_SERVICES),
            look_pre_matri: parseInt(process.env.REACT_APP_LOOK_PRE_MATRI),
            look_post_matri: parseInt(process.env.REACT_APP_LOOK_POST_MATRI),
            comments: process.env.REACT_APP_COMMENTS,

            //step 7
            politicas_privacidad: parseInt(process.env.REACT_APP_POLITICAS_PRIVACIDAD),


        }
    }

    componentDidMount() {
        this.getGenders();
        this.getCountries();
        this.getResidences();
        this.getEstudios();
        this.getOrientations();
        this.getKnowledge();
    }

    /**
     * Petición a la api para obtener el combo
     */
    getGenders = async () => {
        const { url_api, headers_api } = config;
        axios.get(url_api + '/genders', headers_api).then(response => {
            var combo = response.data.map(({ name, id }) => {
                return { value: id, label: name, key: 'genero' }
            });
            this.setState({
                combos: {
                    ...this.state.combos,
                    genders: combo,
                }
            });

        }).catch(error => {
            console.log(error);
        })
    }

    getCountries = async () => {
        const { url_api, headers_api } = config;
        axios.get(url_api + '/countries', headers_api).then(response => {
            var combo = response.data.map(({ name, id }) => {
                return { value: id, label: name, key: 'pais' }
            });
            this.setState({
                combos: {
                    ...this.state.combos,
                    countries: combo
                }
            });

        }).catch(error => {
            console.log(error);
        })
    }

    getEstudios = async () => {
        const { url_api, headers_api } = config;
        axios.get(url_api + '/studies', headers_api).then(response => {
            var combo = response.data.map(({ name_es, id }) => {
                return { value: id, label: name_es, key: 'estudio' }
            });
            this.setState({
                combos: {
                    ...this.state.combos,
                    studies: combo
                }
            });

        }).catch(rs => {
            console.log(rs);
        })
    }

    getResidences = async () => {
        const { url_api, headers_api } = config;
        axios.get(url_api + '/residences', headers_api).then(response => {
            var combo = response.data.map(({ name, id }) => {
                return { value: id, label: name, key: 'tipo_residencia' }
            });
            this.setState({
                combos: {
                    ...this.state.combos,
                    residences: combo
                }
            });

        }).catch(error => {
            console.log(error);
        })
    }

    getOrientations = async () => {
        const { url_api, headers_api } = config;
        axios.get(url_api + '/orientations', headers_api).then(response => {
            var combo = response.data.map(({ name, id }) => {
                return { value: id, label: name, key: 'orientations' }
            });
            this.setState({
                combos: {
                    ...this.state.combos,
                    orientations: combo
                }
            });

        }).catch(error => {
            console.log(error);
        })
    }

    getKnowledge = async () => {
        const { url_api, headers_api } = config;
        axios.get(url_api + '/knowlege', headers_api).then(response => {
            var combo = response.data.map(({ name, id }) => {
                return { value: id, label: name, key: 'knowledge' }
            });
            this.setState({
                combos: {
                    ...this.state.combos,
                    knowledge: combo
                }
            });

        }).catch(error => {
            console.log(error);
        })
    }

    save = () => {
        // confertir la foto en archivo adjunto
        let formData = new FormData();       

{/* <PointSpreadLoading /> */}

        Object.keys(this.state.data).map(item => {
            if (Array.isArray(this.state.data[item])) {
                formData.append(item, JSON.stringify(this.state.data[item]));
            } else {
                formData.append(item, this.state.data[item]);
            }
        })

        //enviar datos
        const { url_api, headers_api } = config;
        axios.post(url_api + '/save', formData, headers_api).then(response => {
            this.setState({ token: response.data.token });
            Swal.fire({
                icon: 'success',
                showConfirmButton: true,
                timer: 3500,
                title: 'Datos guardados correctamente',
                text: 'En breve recibirás un email con tu registro en ' + this.state.data.email
            })

        }).catch(rs => {
            const error = rs.response.data;
            this.showSaveErrors(error);

        })
    }

    /**
     * Muestra los errores en el momento de intentar guardar el formulario
     */
    showSaveErrors(error) {
        let list = ''
        if (error.errors) {
            error.errors.map((er, k) => {
                list = list + '<li key=' + k + '>' + er + '</li>';
            });
        }

        Swal.fire({
            icon: 'error',
            Title: 'Error al guardar los datos',
            html:
                error.message +
                '<hr />' +
                '<ul>' +
                list +
                '</ul>',
        }).then(rs => {
            Scroll.animateScroll.scrollToTop({ duration: 500 });
        });
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    }

    prevStep = () => {
        console.log('atras');
        this.setState({ step: this.state.step - 1 })
    }

    setStep = (tab) => {
        this.setState({ step: tab });
    }

    setData = (key, value) => {

        this.setState({
            data: {
                ...this.state.data,
                [key]: value
            }
        });
    }

    switchTab = () => {
        switch (this.state.step) {
            case 1:
                return (
                    <DatosPersonalesTab
                        data={this.state}
                        setData={this.setData}
                        nextStep={this.nextStep}
                        setStep={this.setStep}
                    />)
                break;
            case 2:
                return (
                    <PhotoTab
                        data={this.state}
                        setData={this.setData}
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        setStep={this.setStep}
                    />)
                break;
            case 3:
                return (
                    <DatosMatriculaTab
                        data={this.state}
                        setData={this.setData}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setStep={this.setStep}
                    />);
                break;

            case 4:
                return (
                    <DatosProfesionalesTab
                        data={this.state}
                        setData={this.setData}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setStep={this.setStep}
                    />);
                break;
            case 5:
                return (
                    <DatosInteresTab
                        data={this.state}
                        setData={this.setData}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setStep={this.setStep}
                    />);
                break;
            case 6:
                return (
                    <DatosExpectativasTab
                        data={this.state}
                        setData={this.setData}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setStep={this.setStep}
                    />);
                break;
            case 7:
                return (
                    <DatosImpresionesTab
                        data={this.state}
                        setData={this.setData}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setStep={this.setStep}
                    />);
                break;
            case 8:
                return (
                    <PolicyTab
                        data={this.state}
                        setData={this.setData}
                        prevStep={this.prevStep}
                        saveData={this.save}
                        setStep={this.setStep}
                    />);
                break;
            default:
                return (
                    <DatosPersonalesTab
                        data={this.state}
                        setData={this.setData}
                        nextStep={this.nextStep}
                        setStep={this.setStep}
                    />);
                break;
        }
    }

    render() {
        if (this.state.token) {
            return (
                <Redirect to={'/response/' + this.state.token} />
            )
        } else {
            return (
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={{ span: 10, offset: 1 }}>
                            <h2 className="title">Formulario de nuevo acceso</h2>
                            <hr />
                            <TransitionGroup>
                                <CSSTransition
                                    timeout={{ enter: 300, exit: 300 }}
                                    classNames={'fade'}
                                >
                                    {this.switchTab()}
                                </CSSTransition>
                            </TransitionGroup>
                        </Col>
                    </Row>

                    {/* <ShowData data={this.state.data} /> */}
                </Container>
            )
        }

    }
}

export default FormPropis
