import React from 'react';
import { Form } from 'react-bootstrap';

const RadioGroup = ({ data, options, handleRadio, name, inline }) => {

    function showOptions() {
        let el = [];
        Object.keys(options).map((item, k) => {
            el.push(
                <Form.Check
                key={k}
                    inline={inline}
                    type="radio"
                    label={options[item]}
                    value={item}
                    id={name+'_'+item}
                    onChange={handleRadio}
                    checked={data[name] == item}
                    name={name}
                />
           )
        })

        return el;
    }


    return (
        <div>
            {showOptions()}
        </div>

    )
}

export default RadioGroup;