import React from 'react'
import CookieConsent from 'react-cookie-consent';

var rgpd = () => {
    return (
        <CookieConsent
            // debug={true}
            location="bottom"
            buttonText="Acepto continuar!!"
            cookieName="surveys_tcm_cookie"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            Este sitio usa cookies para mejorar la experiencia de usuario.{" "}
            <span style={{ fontSize: "10px" }}>
                &copy; Fundació Tecnocampus
    </span>
        </CookieConsent>
    );
}

export default rgpd;