import React, { useState, useRef, useEffect } from 'react'
import { Card, Form, Button, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import NavTab from '../NavTab';
import Scroll from 'react-scroll'
import ErrorRequired from '../ErrorRequired';
import RangeElements from '../../form_elements/RangeElements';


const TabIntereses = (props) => {
    const [status, setStatus] = useState(true);
    const [error_msg, setErrorMsg] = useState([]);
    const [error_fields, setErrorFields] = useState([]);
    const animatedComponents = makeAnimated();

    const valMatrixOptions = {
        val_geo: 'Proximidad geográfica',
        val_horari: 'Horarios',
        val_price: 'Precio',
        val_pay: 'Facilidades de pago',
        val_fame: 'Reputación del centro',
        val_instal: 'Instalaciones',
        val_teacher: 'Profesorado',
        val_upf: 'Por el prestigio del título UPF',
        val_expects: 'Expectativas profesionales',
        val_employment: 'Bolsa de trabajo'

    };

    const scroll = Scroll.animateScroll

    useEffect(_=>{
        scroll.scrollToTop({duration:500});
    },[]);

    /**
     * Vacía los errores del formulario
     */
    function resetData() {
        setStatus(true);
        setErrorMsg([]);
        setErrorFields([]);
    }

    /**
     * Emite la función para meter los datos en el componente padre de los radios
     */
    function handleRadio(e) {
        const { name, value } = e.target;
        props.setData(name, value);
    }

     /**
     * Emite la función para meter los datos en el componente padre de los radios
     */
    function handleRange(e) {
        const { name, value } = e;
        props.setData(name, value);
    }

    /**
     * Emite la funcion para meter el valor del combo en el atributo del componente padre de los select
     * @param {value,label,key} param0 
     */
    function setFieldFromComboOrientationsMultiple(data) {
        let key = 'orientations';
        let elements = data ? data : [];
        props.setData(key, elements);
        resetData();
    }

    /**
     * Emite la funcion para meter el valor del combo en el atributo del componente padre de los select
     * @param {value,label,key} param0 
     */
    function setFieldFromComboKnowledgeMultiple(data) {
        let key = 'knowledge';
        let elements = data ? data : [];
        props.setData(key, elements);
        resetData();
    }

    /**
     * Establece el valor seleccionado en el combo multiple de orientations
     */
    function orientationsSelected() {
        let defaultValue = null;

        //si viene definido el valor por el padre
        if (props.data.data.orientations.length > 0) {
            let selected = props.data.data.orientations;
            defaultValue = props.data.combos.orientations.filter(item => {
                return selected.find(sel => sel.value === item.value);
            });
        }
        return defaultValue;
    }

    /**
    * Establece el valor seleccionado en el combo multiple de knowledge
    */
    function knowledgeSelected() {
        let defaultValue = null;

        //si viene definido el valor por el padre
        if (props.data.data.knowledge.length > 0) {
            let selected = props.data.data.knowledge;
            defaultValue = props.data.combos.knowledge.filter(item => {
                return selected.find(sel => sel.value === item.value);
            });
        }
        return defaultValue;
    }



    /**
     * Comprueba si un select multiple obligatorio está vació o tiene datos
     * @param {*} key 
     */
    function checkRequiredMultipleCombo(key) {
        if (!props.data.data[key] || props.data.data[key].length === 0) {
            setStatus(false);
            error_msg.push('El campo ' + key + ' es obligatorio');
            error_fields.push(key);
            setErrorMsg(error_msg);
            setErrorFields(error_fields);
            return false;
        }
        return true;
    }

    /**
     * Valida todos los campos antes de continuar al siguiente paso
     */
    function validate() {
        let valid = true;
        // if (!checkRequiredValue(ciudad_curso)) { valid = false; }

        // if (!checkRequiredCombo('estudio')) { valid = false; }
        if (!checkRequiredMultipleCombo('orientations')) { valid = false; }
        if (!checkRequiredMultipleCombo('knowledge')) { valid = false; }

        if (valid) {
            resetData();
            props.nextStep();
        }else{
           ErrorRequired();
        }

    }
    return (

        <Card className="main-card">
            <Card.Header>
            <NavTab 
                setStep={props.setStep} 
                current={props.data.step}
                data={props.data.data}
                />
            </Card.Header>
            <Card.Body>
                {!status &&
                    <Alert variant='danger'>
                        <ul> Errores encontrados:
                        {error_msg.map((err, k) => {
                            return (
                                <li key={k}>{err}</li>
                            );
                        })}
                        </ul>
                    </Alert>
                }

                <Card.Title>5. Selección de titulación y de centro</Card.Title><hr />

                {/* PRIMERA FILA INTERES */}

                <Row>
                    {/*  */}
                    <Col>
                        <Form.Group>
                            <Form.Label className='required' >Indica con cuál de estas dos afirmaciones estás más de acuerdo</Form.Label>

                            <Form.Check
                                checked={props.data.data.motivo_estudios === 'tematica'}
                                value='tematica'
                                type={'radio'}
                                onChange={handleRadio}
                                id="int_tematica"
                                label={'He escogido esta titulación porque me interesa la temática '}
                                name='motivo_estudios'
                            />

                            <Form.Check
                                checked={props.data.data.motivo_estudios === 'sal_profesional'}
                                value='sal_profesional'
                                type={'radio'}
                                onChange={handleRadio}
                                id='int_prof'
                                label='He escogido esta titulación por las salidas profesionales'
                                name='motivo_estudios'
                            />
                        </Form.Group>

                    </Col>
                </Row>
                <hr />
                {/* SEGUNDA FILA ORIENTATIONS*/}
                <Row>
                    <Col>
                        <div className="form-group">
                            <div>
                                <label className="required">¿Qué orientaciones te han sido más útiles a la hora de escoger la titulación?</label>
                                <br />
                                <small>(puedes marcar más de una opción)</small>
                            </div>
                            <Select
                                isMulti
                                value={orientationsSelected()}
                                loading={props.data.combos.orientations.length === 0}
                                components={animatedComponents}
                                options={props.data.combos.orientations}
                                onChange={setFieldFromComboOrientationsMultiple}
                                className={error_fields.find(el => el === 'orientations') ? 'select-is-invalid' : ''}
                                name='orientations'
                            />
                        </div>
                    </Col>
                </Row>
                <hr />
                {/* TERCERA FILA MATRIX VAL */}
                <Row>
                    <Col>
                        <div className="form-group">
                            <div>
                                <label className="required">Valora del 0 (menos importante) al 10 (muy importante) los diferentes aspectos que te han ayudado a decidir a optar por los estudios en TecnoCampus:</label>
                            </div>
                            <RangeElements
                                options={valMatrixOptions}
                                min={0}
                                max={10}
                                handleRange={handleRange}
                                data={props.data.data}
                            />
                        </div>
                    </Col>
                </Row>
                <hr />
                {/* CUARTA FILA KNOWLEGE*/}
                <Row>
                    <Col>
                        <div className="form-group">
                            <div>
                                <label className="required">¿Cómo conociste el centro?</label>
                                <br />
                                <small>(puedes marcar más de una opción)</small>
                            </div>
                            <Select
                                isMulti
                                value={knowledgeSelected()}
                                loading={props.data.combos.knowledge.length === 0}
                                components={animatedComponents}
                                options={props.data.combos.knowledge}
                                onChange={setFieldFromComboKnowledgeMultiple}
                                className={error_fields.find(el => el === 'knowledge') ? 'select-is-invalid' : ''}
                                name='knowledge'
                            />
                        </div>
                    </Col>
                </Row>

            </Card.Body>
            <Card.Footer>
                <Button variant="warning" onClick={props.prevStep}>Anterior</Button>
                <Button className="pull-right" onClick={validate}>Siguiente</Button>
            </Card.Footer>

        </Card>
    );
}
export default TabIntereses;

