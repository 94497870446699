import React, { useState, useRef, useEffect } from 'react'
import { Card, Form, Button, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faIdCard, faPhone, faBuilding } from '@fortawesome/free-solid-svg-icons'
import NavTab from '../NavTab';
import Swal from 'sweetalert2';
import Scroll from 'react-scroll'
import ErrorRequired from '../ErrorRequired';


const TabDatosPersonales = (props) => {
    const [status, setStatus] = useState(true);
    const [error_msg, setErrorMsg] = useState([]);
    const [error_fields, setErrorFields] = useState([]);


    const nombre = useRef(null);
    const primer_apellido = useRef(null);
    const segundo_apellido = useRef(null);
    const email = useRef(null);
    const telefono = useRef(null);
    const dni = useRef(null);
    const ciudad = useRef(null);
    const scroll = Scroll.animateScroll

    useEffect(_=>{
        scroll.scrollToTop({duration:500});
    },[]);

    /**
     * Vacía los errores del formulario
     */
    function resetData() {
        setStatus(true);
        setErrorMsg([]);
        setErrorFields([]);
    }

    /**
    * Emite la función para meter los datos en el componente padre
    * @param {*} e 
    */
    function setField(e) {
        var key = e.target.name;
        var value = e.target.value;
        props.setData(key, value);
        resetData();
    }

    /**
     * Emite la funcion para meter el valor del combo en el atributo del componente padre
     * @param {value,label,key} param0 
     */
    function setFieldFromCombo({ value, key }) {
        props.setData(key, value);
        resetData();
    }

    /**
     * Establece el valor seleccionado en el combo de genero
     */
    function generoSelected() {
        let defaultValue = null;

        //si viene definido el valor por el padre
        if (props.data.data.genero) {
            let selected = props.data.data.genero;
            defaultValue = props.data.combos.genders.find(item => item.value === selected);
        }

        return defaultValue;
    }

    /**
     * Establece el valor seleccionado en el combo de país
     */
    function countrySelected() {
        let defaultValue = null;
        //si viene definido el valor por el padre
        if (props.data.data.pais) {
            let selected = props.data.data.pais;
            defaultValue = props.data.combos.countries.find(item => item.value === selected);
        }
        return defaultValue;
    }





    /**
     * Comprueba un valor obligatorio
     * @param {} value 
     */
    function checkRequiredValue(value) {
        if (!value.current.value || value.current.value === '') {
            setStatus(false);
            error_msg.push('El campo ' + value.current.name + ' es obligatorio');
            error_fields.push(value.current.name);
            setErrorMsg(error_msg);
            setErrorFields(error_fields);
            return false;
        }
        return true;
    }

    function checkRequiredCombo(key) {
        if (!props.data.data[key] || props.data.data[key] === 0) {
            setStatus(false);
            error_msg.push('El campo ' + key + ' es obligatorio');
            error_fields.push(key);
            setErrorMsg(error_msg);
            setErrorFields(error_fields);
            return false;
        }
        return true;
    }

    /**
     * Valida todos los campos antes de continuar al siguiente paso
     */
    function validate() {
        let valid = true;
        if (!checkRequiredValue(nombre)) { valid = false; }
        if (!checkRequiredValue(primer_apellido)) { valid = false; }
        if (!checkRequiredValue(dni)) { valid = false; }
        if (!checkRequiredValue(email)) { valid = false; }
        if (!checkRequiredValue(telefono)) { valid = false; }
        if (!checkRequiredValue(ciudad)) { valid = false; }

        if (!checkRequiredCombo('genero')) { valid = false; }
        if (!checkRequiredCombo('pais')) { valid = false; }

        if (valid) {
            resetData();
            props.nextStep();
        }else{
            ErrorRequired();
        }

    }



    return (

        <Card className="main-card">
            <Card.Header>
                <NavTab 
                setStep={props.setStep} 
                current={props.data.step}
                data={props.data.data}
                
                />
            </Card.Header>
            <Card.Body>
                {!status &&
                    <Alert variant='danger'>
                        <ul> Errores encontrados:
                        {error_msg.map((err, k) => {
                            return (
                                <li key={k}>{err}</li>
                            );
                        })}
                        </ul>
                    </Alert>
                }

                <Card.Title>1. Datos personales</Card.Title><hr />

                {/* PRIMERA FILA */}

                <Row>
                    {/* NOMBRE */}
                    <Col md={4} sm={12}>
                        <Form.Group>
                            <Form.Label className='required'>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name='nombre'
                                value={props.data.data.nombre ? props.data.data.nombre : ''}
                                ref={nombre}
                                onChange={setField}
                                className={error_fields.find(el => el === 'nombre') ? 'is-invalid' : ''}
                            ></Form.Control>
                        </Form.Group>
                    </Col>

                    {/* Primer Apellido */}
                    <Col md={4} sm={12}>
                        <Form.Group>
                            <Form.Label className='required' >Primer Apellido</Form.Label>
                            <Form.Control
                                type="text"
                                value={props.data.data.primer_apellido ? props.data.data.primer_apellido : ''}
                                name='primer_apellido'
                                ref={primer_apellido}
                                onChange={setField}
                                className={error_fields.find(el => el === 'primer_apellido') ? 'is-invalid' : ''}
                            ></Form.Control>
                        </Form.Group>
                    </Col>

                    {/* Segundo Apellido */}
                    <Col md={4} sm={12}>
                        <Form.Group>
                            <Form.Label>Segundo Apellido</Form.Label>
                            <Form.Control
                                type="text"
                                value={props.data.data.segundo_apellido ? props.data.data.segundo_apellido : ''}
                                name='segundo_apellido'
                                ref={segundo_apellido}
                                onChange={setField}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>

                {/* SEGUNDA FILA */}

                <Row>
                    {/* dni */}
                    <Col md={4} sm={12}>
                        <Form.Group>
                            <Form.Label className='required'>NIF/Pasaporte</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faIdCard} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    value={props.data.data.dni ? props.data.data.dni : ''}
                                    name='dni'
                                    ref={dni}
                                    onChange={setField}
                                    className={error_fields.find(el => el === 'dni') ? 'is-invalid' : ''}
                                ></Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    {/* genero */}
                    <Col md={4} sm={12}>
                        <div className="form-group">
                            <div>
                                <label className="required">Género</label>
                            </div>
                            <Select
                                value={generoSelected()}
                                options={props.data.combos.genders}
                                onChange={setFieldFromCombo}
                                className={error_fields.find(el => el === 'genero') ? 'select-is-invalid' : ''}
                                name='genero'
                            />
                        </div>
                    </Col>

                    {/* telefono */}
                    <Col md={4} sm={12}>
                        <Form.Group>
                            <Form.Label className='required' >Telefono</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPhone} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <Form.Control
                                    type="text"
                                    name='telefono'
                                    value={props.data.data.telefono ? props.data.data.telefono : ''}
                                    ref={telefono}
                                    onChange={setField}
                                    className={error_fields.find(el => el === 'telefono') ? 'is-invalid' : ''}
                                ></Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                </Row>

                {/* TERCERA FILA */}

                <Row>
                    {/* email */}
                    <Col>
                        <Form.Group>
                            <Form.Label className='required' >Email</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                                <Form.Control
                                    type="email"
                                    value={props.data.data.email ? props.data.data.email : ''}
                                    name='email'
                                    ref={email}
                                    onChange={setField}
                                    className={error_fields.find(el => el === 'email') ? 'is-invalid' : ''}
                                ></Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

                {/* CUARTA FILA */}
                <Row>
                    <Col sm={12} md={6}>
                        <div className="form-group">
                            <div>
                                <label className="required">País</label>
                            </div>
                            <Select
                                value={countrySelected()}
                                options={props.data.combos.countries}
                                onChange={setFieldFromCombo}
                                className={error_fields.find(el => el === 'pais') ? 'select-is-invalid' : ''}
                                name='pais'
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group>
                            <Form.Label className='required'>Población</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    name='ciudad'
                                    value={props.data.data.ciudad ? props.data.data.ciudad : ''}
                                    ref={ciudad}
                                    onChange={setField}
                                    className={error_fields.find(el => el === 'ciudad') ? 'is-invalid' : ''}
                                ></Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>




            </Card.Body>
            <Card.Footer>
                <Button className="pull-right" onClick={validate}>Siguiente</Button>
            </Card.Footer>

        </Card>
    );
}
export default TabDatosPersonales;

