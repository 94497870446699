import React, { Component } from 'react'
import { BrowserRouter, Switch, Route} from 'react-router-dom'
import Header from './components/Header';
import FormPropis from './components/propis/FormPropis';
import Home from './components/Home';
import Error404 from './components/errors/Error404';
import Response from './components/Response';

class Router extends Component {

    render() {
        return (

            <BrowserRouter >
                <div id="main">
                    <Header />
                    <Switch >
                        <Route exact path="/" component={Home}></Route>
                        <Route exact path="/propis" component={FormPropis}></Route>
                        <Route exact path="/response/:token" component={Response}></Route>
                        <Route component={Error404}></Route>
                    </Switch>
                </div>


            </BrowserRouter>
        )
    }
}

export default Router;
