import { Navbar } from "react-bootstrap";
import Logo from '../logo-color.svg'
import React from 'react';
import {NavLink} from 'react-router-dom'

var header = () => {
    return (
        <React.Fragment>
            <Navbar id="header" fixed="top" bg="dark" variant="dark">
                <Navbar.Brand >
                    <NavLink to='/'>
                        <img
                            alt=""
                            src={Logo}
                            height="30"
                            className="d-inline-block align-top"
                        />
                    </NavLink>

                </Navbar.Brand>
            </Navbar>
        </React.Fragment>


    );

}

export default header;