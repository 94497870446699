
var config = ({   
        'url_api' : process.env.REACT_APP_API_HOST,
        'headers_api' : { headers: {
            'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN,
            'Content-Type' : 'application/json',
        }},
        'recaptcha_site' : process.env.REACT_APP_SITE_RECAPTCHA

    });

export default config
