import React from 'react'
import { Table, Form } from 'react-bootstrap';
import InputRange from 'react-input-range';
import './RangeStyle.scss';

const RangeElements = props => {
    const { options, min, max, handleRange, data } = props;

    function getElements(key) {
        var options = [];
        for (var i = min; i <= max; i++) {
            options.push(<option key={i}>{i}</option>);
        }
        let td = [<td key={key} className="text-align-center" >
            <InputRange
                maxValue={max}
                minValue={min}
                value={data[key]}
                onChange={value => handleRange({ name:key, value:value })}
            />
        </td>];

        return td;
    }

    function getRows() {
        let tr = [];
        Object.keys(options).map((item, i) => {
            tr.push(
                <tr key={i}>
                    <td>{options[item]}</td>
                    {getElements(item)}
                </tr>
            )
        });
        return tr;
    }

    return (
        <Table >
            <thead>
                <tr>
                    <th></th>
                    <th>Valoración</th>
                </tr>
            </thead>
            <tbody>
                {getRows()}
            </tbody>
        </Table>
    )
}

export default RangeElements;