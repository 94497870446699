import React, { useState, useEffect } from 'react'
import { Card, Button, Row, Col, Form, Alert } from 'react-bootstrap';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import config from '../../../config';
import { BoxLoading, PointSpreadLoading } from 'react-loadingg';
import NavTab from '../NavTab';
import Swal from 'sweetalert2';
import Scroll from 'react-scroll'
import ErrorRequired from '../ErrorRequired';


const TabPolicy = (props) => {

    const [status, setStatus] = useState(true);
    const [error_msg, setErrorMsg] = useState([]);
    const [error_fields, setErrorFields] = useState([]);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [waitResponse, setWaitResponse] = useState(false);

    const scroll = Scroll.animateScroll;

    useEffect(_ => {
        scroll.scrollToTop({ duration: 500 });
    }, []);


    const verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        // console.log(recaptchaToken, "<= your recaptcha token")
        setCaptchaToken(recaptchaToken);
    }

    /**
     * Emite la función para meter los datos en el componente padre de los radios
     */
    function handleCheck(e) {
        const { name, checked } = e.target;
        const value = checked ? 1 : 0;
        props.setData(name, value);
        resetData();
    }

    /**
    * Comprueba un valor obligatorio
    * @param {} value 
    */
    function checkRequiredValue(value) {
        if (!props.data.data[value] || props.data.data[value] === 0) {
            setStatus(false);
            error_msg.push('Las políticas de privaidad deben ser aceptadas');
            error_fields.push(props.data.data[value]);
            setErrorMsg(error_msg);
            setErrorFields(error_fields);
            return false;
        }
        return true;
    }

    /**
   * Valida todos los campos antes de continuar al siguiente paso
   */
    function validate() {
        let valid = true;
        setWaitResponse(true);
        if (!checkRequiredValue('politicas_privacidad')) { valid = false; }
        if (!captchaToken) {
            setStatus(false);
            error_msg.push('No se ha podido validar el Captcha');
            error_fields.push('captcha');
            setErrorMsg(error_msg);
            setErrorFields(error_fields);
            valid = false;
        }

        if (valid) {
            resetData();
            props.saveData();
            setWaitResponse(false);
        } else {
            setWaitResponse(false);
            ErrorRequired();
        }

    }

    /**
    * Vacía los errores del formulario
    */
    function resetData() {
        setStatus(true);
        setErrorMsg([]);
        setErrorFields([]);
    }

    useEffect(_ => {
        loadReCaptcha(config.recaptcha_site)
    });


    return (

        <Card className="main-card">
            <Card.Header>
                <NavTab
                    setStep={props.setStep}
                    current={props.data.step}
                    data={props.data.data}
                />
            </Card.Header>
            <Card.Body>
                {!status &&
                    <Alert variant='danger'>
                        <ul> Errores encontrados:
                        {error_msg.map((err, k) => {
                            return (
                                <li key={k}>{err}</li>
                            );
                        })}
                        </ul>
                    </Alert>
                }

                <Card.Title>8. Políticas de Privacidad</Card.Title><hr />

                {/* PRIMERA FILA expectativas */}

                <Row>
                    <Col >
                        <div className="form-group">
                            <p>
                                Los datos serán incluidos en un fichero de Fundación TecnoCampus Mataró-Maresme para la finalidad indicada,
                                la cual adopta las medidas de seguridad necesarias, de acuerdo con la normativa aplicable que dispone la
                                Ley Orgánica 15/1999 del 13 de diciembre, de Protección de Datos de Carácter Personal.
                                Los datos estadísticos no se utilizan con nombres asociados. Acepto expresamente,
                                vía correo portal o electrónico o SMS, recibir información del TCM relativa a las funciones que le son propias o no acepto.
                                Podrá ejercer sus derechos de acceso, rectificación, cancelación, en los términos establecidos en la legislación vigente,
                                dirigiéndose a Gestión Académica de TecnoCampus Mataró-Maresme.
                            </p>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>

                    <Col sm={12} md={8}>

                        <Form.Check
                            value='1'
                            type='checkbox'
                            name='politicas_privacidad'
                            checked={props.data.data.politicas_privacidad == 1}
                            onChange={handleCheck}
                            id="policy"
                            label="Acepto las políticas de privacidad"
                        >
                        </Form.Check>
                    </Col>


                    <Col sm={12} md={4} >
                        <ReCaptcha
                            invisible={false}
                            sitekey={config.recaptcha_site}
                            action="masters_form"
                            verifyCallback={verifyCallback}
                        />
                        {!captchaToken &&
                            <PointSpreadLoading

                            />
                        }

                    </Col>
                </Row>

            </Card.Body>
            <Card.Footer>
                {waitResponse &&
                    <PointSpreadLoading

                    />
                }
                <Button variant="warning" onClick={props.prevStep}>Anterior</Button>
                <Button className="pull-right" variant="success" disabled={waitResponse} onClick={validate}>Finalizar</Button>
            </Card.Footer>

        </Card>
    );
}
export default TabPolicy;

