import React, {useEffect} from 'react';
import { Card, Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCity, faSuitcase } from '@fortawesome/free-solid-svg-icons'
import NavTab from '../NavTab';
import Scroll from 'react-scroll'


const TabProfesionales = (props) => {

    const scroll = Scroll.animateScroll;

    useEffect(_=>{
        scroll.scrollToTop({duration:500});
    },[]);


    /**
    * Emite la función para meter los datos en el componente padre
    * @param {*} e 
    */
    function setField(e) {
        var key = e.target.name;
        var value = e.target.value;
        props.setData(key, value);
    }

    /**
     * Emite la funcion para meter el valor del combo en el atributo del componente padre
     * @param 
     */
    function setFieldSwitch(e) {
        var key = e.target.name;
        var value = e.target.checked ? 1 : 0;
        props.setData(key, value);
    }

    
    return (
        <Card className="main-card">
            <Card.Header>
            <NavTab 
            setStep={props.setStep} 
            current={props.data.step}
            data={props.data.data}
            />
            </Card.Header>
            <Card.Body>
                <Card.Title>4. Datos Profesionales</Card.Title><hr />

                {/* PRIMERA FILA */}

                <Row>
                    <Col>
                    <blockquote>Situación Profesional</blockquote>
                        <div className="form-group">
                           <Form.Check 
                           type="switch"
                           name="job_10h"
                           id="job_10h"
                           checked={props.data.data.job_10h}
                           label="¿Trabajas más de 10 horas a la semana?"
                           onChange={setFieldSwitch}
                           />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                           <Form.Check 
                           type="switch"
                           name="job_study"
                           id="job_study"
                           checked={props.data.data.job_study}
                           label="¿Tu trabajo, ¿tiene relación con los estudios que haces?"
                           onChange={setFieldSwitch}
                           />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                           <Form.Check 
                           type="switch"
                           name="job_fix"
                           id="job_fix"
                           checked={props.data.data.job_fix}
                           label="¿Tu trabajo es fijo?"
                           onChange={setFieldSwitch}
                           />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                           <Form.Check 
                           type="switch"
                           name="job_weekend"
                           id="job_weekend"
                           checked={props.data.data.job_weekend}
                           label="¿Trabajas los fines de semana?"
                           onChange={setFieldSwitch}
                           />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                           <Form.Check 
                           type="switch"
                           name="job_search"
                           id="job_search"
                           checked={props.data.data.job_search}
                           label="¿Buscas Trabajo?"
                           onChange={setFieldSwitch}
                           />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                           <Form.Check 
                           type="switch"
                           name="practises"
                           id="practises"
                           checked={props.data.data.practises}
                           label="¿Estás interesado en hacer prácticas?"
                           onChange={setFieldSwitch}
                           />
                        </div>
                    </Col>
                </Row>
<hr/>
                <Row>
                {/* City Job */}
                <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label className='required'>Població on treballes</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCity} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                name='job_city'
                                value={props.data.data.job_city ? props.data.data.job_city : ''}
                                onChange={setField}
                            ></Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                     {/* Company Name */}
                <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label className='required'>Empresa on treballes</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faSuitcase} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            <Form.Control
                                type="text"
                                name='company_name'
                                value={props.data.data.company_name ? props.data.data.company_name : ''}
                                onChange={setField}
                            ></Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    </Row>

            </Card.Body>
            <Card.Footer>
                <Button variant="warning" onClick={props.prevStep}>Anterior</Button>
                <Button className="pull-right" onClick={ props.nextStep}>Siguiente</Button>
            </Card.Footer>

        </Card>
    );
}
export default TabProfesionales;

