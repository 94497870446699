import React, { useRef, useState, useEffect } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { Form, Button, Row, Col } from 'react-bootstrap';

const Photo = ({prevFoto,handleFoto}) => {

    const cropper = useRef();
    const fileRef = useRef();


    const [file, setFile] = useState(null);
    const [cropResult, setCrop] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null)
    const [src, setSrc] = useState(null)

    // Se ejecuta al ordenar el recorte final
    function setCropped() {
        if(cropper.current){
            setCrop(cropper.current.getCroppedCanvas().toDataURL());

            cropper.current.getCroppedCanvas().toBlob(blob => {
                handleFoto(blob)
                setCroppedImage(blob)
                getObjectToUrl(blob);

            });
        }
    }

    // Obtiene url válida para mostrar una imagen con el recorte final
    function getObjectToUrl(blob) {

        let reader = new FileReader();
        reader.onload = _ => {
            setSrc(reader.result);
        }
        reader.readAsDataURL(blob);

    }

    // Obtiene el archivo una vez se ha seleccionado del ordenador
    function getFile(e) {
        resetCrop();
        const reader = new FileReader();
        reader.onload = _ => {
            setFile(reader.result)
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    function chooseImage(){
        fileRef.current.click();
    }

    function resetCrop(){
        setSrc(null);
        setCrop(null);
        setFile(null)
        setCroppedImage(null)
    }

    useEffect(()=>{
        if(prevFoto){
            getObjectToUrl(prevFoto)
        }
    },[]);


    if (src) {

        return (
            <div className="text-align-center">
                <img src={src} style={{width:176}} alt="foto" />
                <hr/>
                <Button style={{width:'100%'}} variant="danger" onClick={resetCrop} className="pull-right ">Cambiar Imagen</Button>
            </div>

        )
    } else {
        return (
            <div>
                <input
                    style={{display:'none'}}
                    type='file'
                    onChange={getFile}
                    ref={fileRef}
                    name={'foto'}
                />
                <Button style={{width:'100%'}} variant="primary" onClick={chooseImage} className="pull-right ">Seleccionar Imagen</Button>
                <hr />
                
                <Row>
                    <Col>
                        {file &&
                            <div className="text-align-center">
                                <hr/>
                                <h3 className="subheader">Ajusta la imagen</h3>
                                <hr />
                                <Cropper
                                    ref={cropper}
                                    src={file}
                                    style={{ height: 220, width: '100%' }}
                                    // Cropper.js options
                                    aspectRatio={176 / 220}
                                    dragMode='move'
                                    cropBoxMovable={false}
                                    cropBoxResizable={false}
                                    minCropBoxHeight={220}
                                    minCropBoxWidth={176}
                                    guides={false}
                                />
                            </div>
                        }

                    </Col>
                    {/* <Col sm={12} md={6}>
                        {cropResult &&
                            <div>
                                <h3 className="subheader">Resultado del recorte</h3>
                                <img style={{ width: 176, height: 220, border: 'solid 1px' }} src={cropResult} alt="cropped image" />
                            </div>

                        }

                    </Col> */}
                </Row>
                <hr/>
                {file &&
                <Row>
                <Col>
                    <Button style={{width:'100%'}} variant="success" onClick={setCropped} className="pull-right">Recortar Imagen</Button>
                </Col>
            </Row>
                }
                

            </div>

        )

    }

}

export default Photo;