import React from 'react';
import { Nav } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect'

const NavTab = ({ setStep, current, data }) => {
    function handleStep(e) {
        const n = e.target.getAttribute('step');
        if (data.politicas_privacidad) {
            setStep(Number.parseInt(n));
        } else {
            Swal.fire({
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: 'Aceptar',
                timer: 3000,
                title: 'OOOpps!!',
                text: "No puedes navegar entre pestañas hasta no haber terminado el formulario"
            });
        }
    }
    if (!isMobile) {
        return (

            <Nav fill variant="tabs" >
                <Nav.Item>
                    <Nav.Link className={current == 1 ? 'active' : ''} step={1} onClick={handleStep}>Datos Personales</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={current == 2 ? 'active' : ''} step={2} onClick={handleStep}>Foto</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={current == 3 ? 'active' : ''} step={3} onClick={handleStep}>Datos Matrícula</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={current == 4 ? 'active' : ''} step={4} onClick={handleStep}>Datos Profesionales</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={current == 5 ? 'active' : ''} step={5} onClick={handleStep}>Intereses</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={current == 6 ? 'active' : ''} step={6} onClick={handleStep}>Expectativas</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={current == 7 ? 'active' : ''} step={7} onClick={handleStep}>Impresiones</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={current == 8 ? 'active' : ''} step={8} onClick={handleStep}>Condiciones</Nav.Link>
                </Nav.Item>
            </Nav>
        )
    } else {
        return <hr />
    }


}

export default NavTab;