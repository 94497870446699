import React, { useState, useEffect } from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap';
import CropperPhoto from '../../form_elements/Photo'
import {Alert} from 'react-bootstrap'
import NavTab from '../NavTab';
import Swal from 'sweetalert2';
import Scroll from 'react-scroll'
import ErrorRequired from '../ErrorRequired';


const TabPhoto = (props) => {
    const [foto, setFoto] = useState(null)

    const [status, setStatus] = useState(true);
    const [error_msg, setErrorMsg] = useState([]);
    const [error_fields, setErrorFields] = useState([]);
    const scroll = Scroll.animateScroll;

    useEffect(_=>{
        scroll.scrollToTop({duration:500});
    },[]);


    /**
     * Vacía los errores del formulario
     */
    function resetData() {
        setStatus(true);
        setErrorMsg([]);
        setErrorFields([]);
    }

    function handleFoto(file) {
        resetData();
        setFoto(file);
        props.setData('foto', file);
    }

    /**
    * Valida todos los campos antes de continuar al siguiente paso
    */
    function validate() {
        let valid = true;

        if (!props.data.data.foto) {
            setStatus(false);
            error_msg.push('Para continuar hay que cargar una foto recortada');
            error_fields.push('foto');
            setErrorMsg(error_msg);
            setErrorFields(error_fields);
            valid = false;
        }

        if (valid) {
            resetData();
            props.nextStep();
        }else{
            ErrorRequired();
        }

    }

    return (

        <Card className="main-card">
            <Card.Header>
            <NavTab 
                setStep={props.setStep} 
                current={props.data.step}
                data={props.data.data}
                />
            </Card.Header>
            <Card.Body>

                {!status &&
                    <Alert variant='danger'>
                        <ul> Errores encontrados:
                        {error_msg.map((err, k) => {
                            return (
                                <li key={k}>{err}</li>
                            );
                        })}
                        </ul>
                    </Alert>
                }


                <Card.Title>2. Fotografía</Card.Title><hr />
                <CropperPhoto
                    prevFoto={props.data.data.foto}
                    handleFoto={handleFoto} />


            </Card.Body>
            <Card.Footer>
                <Button variant="warning" onClick={props.prevStep}>Anterior</Button>
                <Button className="pull-right" onClick={validate}>Siguiente</Button>
            </Card.Footer>

        </Card>
    );
}
export default TabPhoto;

