import React from 'react';
import Scroll from 'react-scroll';
import Swal from 'sweetalert2';

const ErrorRequired = () => {
    const scroll = Scroll.animateScroll;
    return(
        Swal.fire({
            icon: 'error',
            showConfirmButton: true,
            timer: 2000,
            title: 'Datos Incompletos',
            text: 'más información en la cabecera de la ficha'
        }).then(response => {
            scroll.scrollToTop({ duration: 500 });
        })
    )
   
}
export default ErrorRequired;