import React, { useState, useRef, useEffect} from 'react'
import { Card, Form, Button, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import NavTab from '../NavTab';
import Swal from 'sweetalert2';
import Scroll from 'react-scroll'
import ErrorRequired from '../ErrorRequired';


const TabMatricula = (props) => {
    const [status, setStatus] = useState(true);
    const [error_msg, setErrorMsg] = useState([]);
    const [error_fields, setErrorFields] = useState([]);


    const ciudad_curso = useRef(null);
    const scroll = Scroll.animateScroll;

    useEffect(_=>{
        scroll.scrollToTop({duration:500});
    },[]);


    /**
     * Vacía los errores del formulario
     */
    function resetData() {
        setStatus(true);
        setErrorMsg([]);
        setErrorFields([]);
    }

    /**
    * Emite la función para meter los datos en el componente padre
    * @param {*} e 
    */
    function setField(e) {
        var key = e.target.name;
        var value = e.target.value;
        props.setData(key, value);
        resetData();
    }

    /**
     * Emite la funcion para meter el valor del combo en el atributo del componente padre
     * @param {value,label,key} param0 
     */
    function setFieldFromCombo({ value, key }) {
        props.setData(key, value);
        resetData();
    }

    /**
     * Establece el valor seleccionado en el combo de genero
     */
    function estudioSelected() {
        let defaultValue = null;

        //si viene definido el valor por el padre
        if (props.data.data.estudio) {
            let selected = props.data.data.estudio;
            defaultValue = props.data.combos.studies.find(item => item.value === selected);
        }
        return defaultValue;
    }

    /**
     * Establece el valor seleccionado en el combo de país
     */
    function residenceSelected() {
        let defaultValue = null;
        //si viene definido el valor por el padre
        if (props.data.data.tipo_residencia) {
            let selected = props.data.data.tipo_residencia;
            defaultValue = props.data.combos.residences.find(item => item.value === selected);
        }
        return defaultValue;
    }

    /**
     * Comprueba un valor obligatorio
     * @param {} value 
     */
    function checkRequiredValue(value) {
        if (!value.current.value || value.current.value === '') {
            setStatus(false);
            error_msg.push('El campo ' + value.current.name + ' es obligatorio');
            error_fields.push(value.current.name);
            setErrorMsg(error_msg);
            setErrorFields(error_fields);
            return false;
        }
        return true;
    }

    function checkRequiredCombo(key) {
        if (!props.data.data[key] || props.data.data[key] === 0) {
            setStatus(false);
            error_msg.push('El campo ' + key + ' es obligatorio');
            error_fields.push(key);
            setErrorMsg(error_msg);
            setErrorFields(error_fields);
            return false;
        }
        return true;
    }

    /**
     * Valida todos los campos antes de continuar al siguiente paso
     */
    function validate() {
        let valid = true;
        if (!checkRequiredValue(ciudad_curso)) { valid = false; }

        if (!checkRequiredCombo('estudio')) { valid = false; }
        if (!checkRequiredCombo('tipo_residencia')) { valid = false; }

        if (valid) {
            resetData();
            props.nextStep();
        }else{            
           ErrorRequired()
        }

    }
    return (

        <Card className="main-card">
            <Card.Header>
            <NavTab 
                setStep={props.setStep} 
                current={props.data.step}
                data={props.data.data}
                />
            </Card.Header>
            <Card.Body>
                {!status &&
                    <Alert variant='danger'>
                        <ul> Errores encontrados:
                        {error_msg.map((err, k) => {
                            return (
                                <li key={k}>{err}</li>
                            );
                        })}
                        </ul>
                    </Alert>
                }

                <Card.Title>3. Datos Matriculación</Card.Title><hr />

                {/* PRIMERA FILA */}

                <Row>
                    {/* ESTUDIOS */}
                    <Col>
                        <div className="form-group">
                            <div>
                                <label className="required">Estudio Matricula</label>
                            </div>
                            <Select
                                value={estudioSelected()}
                                options={props.data.combos.studies}
                                onChange={setFieldFromCombo}
                                className={error_fields.find(el => el === 'estudio') ? 'select-is-invalid' : ''}
                                name='estudio'
                            />
                        </div>
                    </Col>

                    {/* SEGUNDA FILA */}

                </Row>
                <Row>
                    {/* CIUDAD CURSO */}
                    <Col sm={12} md={6}>
                        <Form.Group>
                            <Form.Label className='required'>Población durante el curso</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    name='ciudad_curso'
                                    value={props.data.data.ciudad_curso ? props.data.data.ciudad_curso : ''}
                                    ref={ciudad_curso}
                                    onChange={setField}
                                    className={error_fields.find(el => el === 'ciudad_curso') ? 'is-invalid' : ''}
                                ></Form.Control>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    {/* TIPO RESIDENCIA */}
                    <Col sm={12} md={6}>
                        <div className="form-group">
                            <div>
                                <label className="required">Tipo de residencia</label>
                            </div>
                            <Select
                                value={residenceSelected()}
                                options={props.data.combos.residences}
                                onChange={setFieldFromCombo}
                                className={error_fields.find(el => el === 'tipo_residencia') ? 'select-is-invalid' : ''}
                                name='tipo_residencia'
                            />
                        </div>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <Button variant="warning" onClick={props.prevStep}>Anterior</Button>
                <Button className="pull-right" onClick={validate}>Siguiente</Button>
            </Card.Footer>

        </Card>
    );
}
export default TabMatricula;

