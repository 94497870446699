import React, { useState, useEffect } from 'react'
import { Card,  Button, Row, Col } from 'react-bootstrap';
import NavTab from '../NavTab';
import Scroll from 'react-scroll'
import RangeElements from '../../form_elements/RangeElements';


const TabExpectativas = (props) => {
   
    const valMatrixOptions = {
        expect_knowlege: 'Adquirir conocimientos profundos en las áreas de la titulación',
        expect_tuto: 'Recibir atención personal y tutorización de los estudios',
        expect_practices: 'Poder hacer prácticas en empresas/instituciones',
        expect_contacts: 'Contactos con expertos y otros compañeros',
        expect_employment: 'Facilitarme la inserción laboral o mejora profesional',
    };
    const scroll = Scroll.animateScroll

    useEffect(_=>{
        scroll.scrollToTop({duration:500});
    },[]);


     /**
     * Emite la función para meter los datos en el componente padre de los radios
     */
    function handleRange(e) {
        const { name, value } = e;
        props.setData(name, value);
    }

    return (

        <Card className="main-card">
            <Card.Header>
            <NavTab 
                setStep={props.setStep} 
                current={props.data.step}
                data={props.data.data}
                />
            </Card.Header>
            <Card.Body>

                <Card.Title>6. Expectativas</Card.Title><hr />

                {/* PRIMERA FILA expectativas */}
                <Row>
                    <Col>
                        <div className="form-group">
                            <div>
                                <label className="required">Valora de 1 (poco importante) a 5 (muy importante) los siguientes aspectos según la importancia que crees que han de tener para recibir una buena formación universitaria: </label>
                            </div>
                            <RangeElements
                                options={valMatrixOptions}
                                min={1}
                                max={5}
                                handleRange={handleRange}
                                data={props.data.data}
                            />
                        </div>
                    </Col>
                </Row>

            </Card.Body>
            <Card.Footer>
                <Button variant="warning" onClick={props.prevStep}>Anterior</Button>
                <Button className="pull-right" onClick={props.nextStep}>Siguiente</Button>
            </Card.Footer>

        </Card>
    );
}
export default TabExpectativas;

