import React from 'react';
import { Jumbotron, Container, Button, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

var Home = () => {
   
    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                <NavLink to="/propis" >
                    <Jumbotron style={{background:'rgb(30, 117, 106)', color:'white'}} className="jumbo" fluid>
                        <Container>
                            <h1>Encuestas de Acceso a Másters Universitarios</h1>                           
                        </Container>
                    </Jumbotron>
                    </NavLink>
                </Col>
                <Col lg={4}>
                <NavLink to="/propis" >
                    <Jumbotron className="jumbo" style={{background:'#edc810'}} fluid>
                        <Container>
                            <h1>Encuestas de Acceso a Estudios Propios</h1>
                           
                        </Container>
                    </Jumbotron>
                    </NavLink>
                </Col>
                <Col lg={4}>
                    <a href="https://enquestes-graus.tecnocampus.cat/">
                        <Jumbotron className="jumbo" fluid>
                            <Container>
                                <h1>Encuestas de Acceso de a Grados</h1>
                            </Container>
                        </Jumbotron>
                    </a>
                </Col>
            </Row>



        </React.Fragment>

    );
}

export default Home;