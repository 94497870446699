import React from 'react';
import './App.scss';
import RGPD from './components/CookieRGPD'
import Router from './Router';
import Footer from './components/Footer';

function App() {
  return (
    <React.Fragment>

      <Router></Router>

      <Footer />

      <RGPD />

    </React.Fragment>

  );
}

export default App;
